<!-- 
	This is the cover sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">

		<a-row type="flex" :gutter="[24, 24]" align="center" class="row-main my-0" style="margin-top: 50px !important;">

			<!-- Sign Up Form Column -->
			<a-col :span="24" :md="24" :lg="24" class="col-form" style="max-width: 400px;">

				<div style="text-align: center">
					<p></p>
					<p class="gradient" style="font-size: 2.7rem;  font-weight: bold; line-height: 1.1;"><span
							style="font-size: 2rem !important;">Complete seu cadastro</span></p>
					<p class="text"><b style="font-size: medium; padding: 0 5px 0 5px;">A comunidade que vai impulsionar seu
							crescimento profissional.</b></p>
					<br>
				</div>

				<!-- Sign Up Form -->
				<a-form id="components-form-demo-normal-signup" class="signup-form" :hideRequiredMark="true">

					<a-row :gutter="[24]">
						<a-col :span="24" :lg="24">
							<a-form-item class="mb-10" label="Qual o seu segmento?" :colon="false">
								<a-select placeholder="Selecione" option-filter-prop="children"
									:filter-option="filterOption">
									<a-select-option value="1">Vendas</a-select-option>
									<a-select-option value="2">Influências</a-select-option>
									<a-select-option value="3">Alta Performance</a-select-option>
									<a-select-option value="4">Networking</a-select-option>
									<a-select-option value="5">Marketing Digital</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :span="24" :md="24">
							<a-form-item class="mb-10" label="Seu WhatsApp" :colon="false">
								<a-input v-model="phone" max-length="15" inputmode="numeric" v-on:input="maskCellphone"
									placeholder="(99) 9 9999-9999" />
							</a-form-item>
						</a-col>
					</a-row>
					<a-form-item style="margin-top: 10px;">
						<a-button style="border-radius: 15px;" type="primary" block class="signup-form-button" :loading="isLoadingFacebook"
							@click="saveProducer()">
							Começar!
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign Up Form -->

				<!-- <p class="font-semibold text-muted text-center">Já faz parte da Liga? <router-link to="/sign-in" class="font-bold text-dark">Entre</router-link></p> -->
			</a-col>
			<!-- / Sign Up Form Column -->
		</a-row>

		<a-layout-footer>

			<!-- Footer Navigation Menu -->
			<a-menu mode="horizontal" style="font-size: smaller;">
				<a-menu-item>Termos & Condições</a-menu-item>
				<a-menu-item>Políticas de Privacidade</a-menu-item>
			</a-menu>
			<!-- / Footer Navigation Menu -->


			<!-- Copyright Notice -->
			<p class="copyright" style="font-size: small;">
				Copyright © 2023 <a href="https://kemosoft.com.br">Kemo</a>
			</p>
			<!-- / Copyright Notice -->

		</a-layout-footer>

	</div>
</template>

<script>

export default ({
	data() {
		return {
			// Sign up form object.
			phone: null,
			isLoadingGoogle: false,
			isLoadingFacebook: false,
			form: this.$form.createForm(this, { name: 'signup_cover' }),
		}
	},
	methods: {
		maskCellphone(event) {
			let value = event.target.value.replace(/\D/g, '');
			value = value.replace(/(\d{2})(\d)/, '($1) $2'); // Adiciona parênteses após os primeiros 2 dígitos
			value = value.replace(/(\d{5})(\d)/, '$1-$2'); // Adiciona hífen após os próximos 5 dígitos
			this.phone = value.trim(); // Remove espaços em branco no início e fim
		},
		saveProducer() {
			this.isLoadingFacebook = true;

			this.$api.dataService.save('producer/finish-registration', {
				"phone": this.phone,
				"businessId": 'e942d4a5-8ae5-4d81-9a39-679e817f4f79'
			})
				.then((res) => {
					localStorage.removeItem('bid');
					localStorage.setItem("userType", 'producer');
					this.$router.push("/dashboards/member");
				})
		},
		// Handles input validation after submission.
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					console.log('Received values of form: ', values);
				}
			});
		},
	},
})

</script>

<style lang="scss">
.gradient {
	background-image: linear-gradient(-45deg, #4286f4, #4435ea, #FE2EE4, #6648F6);
	color: black;
	background-clip: text;
	-webkit-background-clip: text;

	/* Adicionado: */
	-webkit-text-fill-color: transparent;
}
</style>